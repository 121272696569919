import authService, {
  resetValues,
  authCredentials,
  authCredentialsProducer,
} from "./authService";
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { setAgentAuthorizedProducer } from "./authSlice";
interface ErrorWithResponse extends Error {
  response?: {
    data?: {
      code?: string;
    };
  };
}

export const loginProducer = createAsyncThunk(
  "auth/loginProducer",
  async (userCredentials: authCredentials, thunkAPI) => {
    try {
      // Try to login
      const response = await authService.doLoginProducer(userCredentials);
      const payload = await response.data;
      if (response.status === 200) {
        console.log(payload)
        const response2 = await authService.verifyUserType();
        const payload2 = await response2.data;
        if (response2.status === 200) {
          if (payload2.user_type === "USER") {
            return { ...payload, user_type: "USER" };
          } else {
            return thunkAPI.rejectWithValue(
              "Error: No producer account found. Did you mean to login to the Agent Portal?",
            );
          }
        } else {
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
        // return payload;
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            payload.description
              ? payload.description
              : "Unable to login user. Please contact support.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data && error.response.data.description) {
        return thunkAPI.rejectWithValue(error.response.data.description);
      }
      return thunkAPI.rejectWithValue(
        "Service is temporarily unavailable. Please try again later, or contact support.",
      );
    }
  },
);
export const loginAgent = createAsyncThunk(
  "auth/loginAgent",
  async (userCredentials: authCredentials, thunkAPI) => {
    try {
      // Try to login
      const response = await authService.doLoginAgent(userCredentials);
      const payload = await response.data;
      if (response.status === 200) {
        // console.log("Agent Portal Response: ");
        // console.log(response);
        const response2 = await authService.verifyUserType();
        const payload2 = await response2.data;
        if (response2.status === 200) {
          if (payload2.user_type === "AGENT") {
            return { ...payload, user_type: payload2.user_type };
          } else {
            return thunkAPI.rejectWithValue(
              "Error: No agent account found. Did you mean to login to the Producer Dashboard?",
            );
          }
        } else {
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            payload.description
              ? payload.description
              : "Unable to login user. Please contact support.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data && error.response.data.description) {
        return thunkAPI.rejectWithValue(error.response.data.description);
      }
      return thunkAPI.rejectWithValue(
        "Service is temporarily unavailable. Please try again later, or contact support.",
      );
    }
  },
);

export const checkLogin = createAsyncThunk(
  "auth/checkLogin",
  async (_: void, thunkAPI) => {
    try {
      // Try to login
      const response = await authService.doCheckLogin(); // no params needed because cookie should be sent automatically
      const payload = await response.data;
      if (response.status === 200) {
        // auto login was successful
        const response2 = await authService.verifyUserType();
        const payload2 = await response2.data;
        if (response2.status === 200) {
          return { ...payload, user_type: payload2.user_type };
          // if (payload2.user_type === "AGENT") {
          // return { ...payload, user_type: payload2.user_type };
          // } else {
          // return thunkAPI.rejectWithValue(
          // "Error: No agent account found. Did you mean to login to the Producer Dashboard?",
          // );
          // }
        } else {
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            payload.description
              ? payload.description
              : "Unable to login user. Please contact support.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data && error.response.data.description) {
        return thunkAPI.rejectWithValue(error.response.data.description);
      }
      return thunkAPI.rejectWithValue(
        // "Service is temporarily unavailable. Please try again later, or contact support.",
        "",
      );
    }
  },
);

export const login = createAsyncThunk(
  "auth/login",
  async (userCredentials: authCredentials, thunkAPI) => {
    try {
      // Try to login
      const response = await authService.doLogin(userCredentials);
      const payload = await response.data;
      if (response.status === 200) {
        console.log("LOGIN SUCCESS");
        return { ...payload };
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            payload.description
              ? payload.description
              : "Unable to login user. Please contact support.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Unable to login user. Please contact support.",
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data && error.response.data.description) {
        return thunkAPI.rejectWithValue(error.response.data.description);
      }
      return thunkAPI.rejectWithValue(
        "Service is temporarily unavailable. Please try again later, or contact support.",
      );
    }
  },
);
export const logout = createAsyncThunk(
  "auth/logout",
  async (_: void, thunkAPI) => {
    try {
      // Try to log out
      const response = await authService.doLogout();
      if (response.status === 204) {
        return true;
      } else {
        try {
          const payload = await response.data;
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(payload.message);
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue("Unable to logout.");
        }
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to logout.");
    }
  },
);

export const registerProducer = createAsyncThunk(
  "auth/registerProducer",
  async (registerCredentials: authCredentialsProducer, thunkAPI) => {
    try {
      // Try to login
      const registration_response =
        await authService.doRegisterProducer(registerCredentials);
      const registration_payload = await registration_response.data;
      if (registration_response.status === 201) {
        //Registration successful - login user

        const login_dispatch_res = await thunkAPI.dispatch(
          loginProducer({
            email: registerCredentials.email,
            password: registerCredentials.password,
          }),
        );
        //login_dispatch_res.type can be "auth/loginProducer/pending", "auth/loginProducer/fulfilled", or "auth/loginProducer/rejected"
        //were awaiting the login dispatch to finish before continuing
        //so it must be either fulfilled or rejected
        if (login_dispatch_res.type === "auth/loginProducer/fulfilled") {
          //Send email to agent
          try {
            await thunkAPI.dispatch(
              sendAgentAuthorizationEmail({
                producerEmailAddress: registerCredentials.email,
                agentEmailAddress: registerCredentials.agent_email,
                isAfterRegistration: true,
              }),
            );

            //we dont need to do this since its done on backend in registerAgent/User

            //await thunkAPI.dispatch(requestVerificationEmail());
          } catch (error) {
            console.error(error);
          } finally {
            return registration_payload;
          }
        } else {
          return thunkAPI.rejectWithValue(login_dispatch_res.payload);
        }
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            registration_payload.message
              ? registration_payload.message
              : "Error creating account. Please verify your inputs or try again later.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Error creating account. Please verify your inputs or try again later.",
          );
        }
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Error creating account. Please verify your inputs or try again later.",
      );
    }
  },
);
export const registerAgent = createAsyncThunk(
  "auth/registerAgent",
  async (registerCredentials: authCredentials, thunkAPI) => {
    try {
      // Try to login
      const registration_response =
        await authService.doRegisterAgent(registerCredentials);
      const registration_payload = await registration_response.data;
      if (registration_response.status === 201) {
        //Registration successful - login user

        const login_dispatch_res = await thunkAPI.dispatch(
          loginAgent({
            email: registerCredentials.email,
            password: registerCredentials.password,
          }),
        );
        //login_dispatch_res.type can be "auth/loginProducer/pending", "auth/loginProducer/fulfilled", or "auth/loginProducer/rejected"
        //were awaiting the login dispatch to finish before continuing
        //so it must be either fulfilled or rejected
        if (login_dispatch_res.type === "auth/loginAgent/fulfilled") {
          //Send Confirm Email Email
          //try {
          //we dont need to do this since its done on backend in registerAgent/User
          //await thunkAPI.dispatch(requestVerificationEmail());
          //} catch (error) {
          //console.error(error);
          //} finally {
          return registration_payload;
          //}
        } else {
          return thunkAPI.rejectWithValue(login_dispatch_res.payload);
        }
      } else {
        try {
          // Try to get reason from response payload
          return thunkAPI.rejectWithValue(
            registration_payload.message
              ? registration_payload.message
              : "Error creating account. Please verify your inputs or try again later.",
          );
        } catch (error) {
          // Failing above, reject with generic message
          console.error(error);
          return thunkAPI.rejectWithValue(
            "Error creating account. Please verify your inputs or try again later.",
          );
        }
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Error creating account. Please verify your inputs or try again later.",
      );
    }
  },
);
export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (email: string, thunkAPI) => {
    try {
      const response = await authService.doRequestPasswordReset(email);
      const payload = await response.data;
      // console.log(payload);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          payload.error
            ? payload.error
            : "Unable to send reset email. Please contact support.",
        );
      }

      return payload;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Unable to send reset email. Please contact support.",
      );
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (resetValues: resetValues, thunkAPI) => {
    try {
      const response = await authService.doPasswordReset(
        resetValues.email,
        resetValues.rcode,
        resetValues.new_password,
      );
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      }

      if (response.status === 400) {
        const errorMessage = payload.error;
        throw errorMessage;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to reset password. Please contact support.",
        );
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Unable to reset password. Please contact support.",
      );
    }
  },
);
interface ErrorWithResponse extends Error {
  response?: {
    data?: {
      code?: string;
    };
  };
}

export const requestVerificationEmail = createAsyncThunk(
  "auth/requestVerificationEmail",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.requestVerificationEmail();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Verification email could not be sent. Please try again later.",
        );
      }
    } catch (error) {
      if (
        (error as ErrorWithResponse).response?.data?.code ===
        "TOO_MANY_ATTEMPTS_TRY_LATER"
      ) {
        return thunkAPI.rejectWithValue(
          "Please wait a few minutes before requesting another verification email.",
        );
      } else {
        return thunkAPI.rejectWithValue(
          "Verification email could not be sent. Please try again later.",
        );
      }
    }
  },
);

export const checkEmailVerification = createAsyncThunk(
  "auth/checkEmailVerification",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.checkEmailVerification();
      const payload = await response.data;

      if (response.status === 200) {
        if (payload.email_verified === true) {
          return payload;
        } else {
          return thunkAPI.rejectWithValue({
            status: 200,
            message: "Email not verified.",
          });
        }
      }

      if (response.status === 500) {
        return thunkAPI.rejectWithValue("Email could not be verified.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Email could not be verified.");
    }
  },
);
export const getAllUsersForAgent = createAsyncThunk(
  "auth/getAllUsersForAgent",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.getAllUsersForAgent();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get users.");
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to get users.");
    }
  },
);
export const checkAgentAuthorizedProducer = createAsyncThunk(
  "auth/checkAgentAuthorizedProducer",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.checkAgentAuthorizedProducer();
      const payload = await response.data;

      if (response.status === 200) {
        return payload.payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get users.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to get users.");
    }
  },
);
export const authorizeOrDenyProducerAccess = createAsyncThunk(
  "auth/authorizeOrDenyProducerAccess",
  async (params: any, thunkAPI) => {
    try {
      const response = await authService.authorizeOrDenyProducerAccess(params);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to authorize user.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to authorize user.");
    }
  },
);
export const createAgencyProfile = createAsyncThunk(
  "auth/createAgencyProfile",
  async (params: any, thunkAPI) => {
    try {
      const response = await authService.createAgencyProfile(params);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to create agency profile.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to create agency profile.");
    }
  },
);
export const checkAgentRegistrationAuthorization = createAsyncThunk(
  "auth/checkAgentRegistrationAuthorization",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.checkAgentRegistrationAuthorization();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to update agency profile.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update agency profile.");
    }
  },
);
export const checkAgencyRegistration = createAsyncThunk(
  "auth/checkAgencyRegistration",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.checkAgencyRegistration();
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to check agency profile.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to check agency profile.");
    }
  },
);

export const sendAgentAuthorizationEmail = createAsyncThunk(
  "auth/sendAgentAuthorizationEmail",
  async (params: any, thunkAPI) => {
    try {
      const response = await authService.sendAgentAuthorizationEmail(params);
      const payload = await response.data;
      if (response.status === 200) {
        return { ...payload, agent_email: params.agentEmailAddress.trim() };
      } else {
        return thunkAPI.rejectWithValue("Unable to update agency profile.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update agency profile.");
    }
  },
);
export const getLastEnteredAgentEmail = createAsyncThunk(
  "auth/getLastEnteredAgentEmail",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.getLastEnteredAgentEmail();
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to update agency profile.");
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to update agency profile.");
    }
  },
);

export const getAgencyRegistration = createAsyncThunk(
  "auth/getAgencyRegistration",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.getAgencyRegistration();
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get profile.");
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to get profile.");
    }
  },
);
export const getPermissions = createAsyncThunk(
  "auth/getPermissions",
  async (_: void, thunkAPI: any) => {
    try {
      let userType;
      try {
        userType = thunkAPI.getState().auth.userType;
      } catch (error) {
        return thunkAPI.rejectWithValue("Unable to get permssions.");
      }

      if (userType === "USER") {
        // make sure the user's access has not been restricted

        const response_authorized_producer =
          await authService.checkAgentAuthorizedProducer();
        const payload_authorized_producer =
          await response_authorized_producer.data;

        if (response_authorized_producer.status === 200) {
          if ("payload" in payload_authorized_producer) {
            if ("is_authorized" in payload_authorized_producer.payload) {
              thunkAPI.dispatch(
                setAgentAuthorizedProducer(
                  payload_authorized_producer.payload.is_authorized,
                ),
              );
            }
          }
        }
      }
      const response = await authService.getPermissions();
      const payload = await response.data;
      if (response.status === 200) {
        if (payload.hasOwnProperty("permissions")) {
          return payload.permissions;
        } else {
          return thunkAPI.rejectWithValue("Unable to get permssions.");
        }
      } else {
        return thunkAPI.rejectWithValue("Unable to get permssions.");
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to get permssions.");
    }
  },
);
export const alertAccountDeletionRequest = createAsyncThunk(
  "auth/alertAccountDeletionRequest",
  async (_: void, thunkAPI) => {
    try {
      const response = await authService.alertAccountDeletionRequest();
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to submit account deletion request. Please contact support@aghedgedesk.com to complete account deletion.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to submit account deletion request. Please contact support@aghedgedesk.com to complete account deletion.",
      );
    }
  },
);
export const alertAccountDeletionRequestNonAuthenticated = createAsyncThunk(
  "auth/alertAccountDeletionRequestNonAuthenticated",
  async (creds: any, thunkAPI) => {
    try {
      const loginResult = await thunkAPI.dispatch(login(creds));
      await unwrapResult(loginResult);
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to validate credentials. Please contact support@aghedgedesk.com for assistance with account deletion if you believe your credentials are correct.",
      );
    }
    thunkAPI.dispatch(alertAccountDeletionRequest());
  },
);
